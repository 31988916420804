	/*
  	Flaticon icon font: Flaticon
  	Creation date: 10/02/2019 11:17
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff2") format("woff2"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-plane:before { content: "\f100"; }
.flaticon-container:before { content: "\f101"; }
.flaticon-truck:before { content: "\f102"; }
.flaticon-warehouse:before { content: "\f103"; }
.flaticon-boat-ship:before { content: "\f104"; }
.flaticon-hand-box:before { content: "\f105"; }
.flaticon-wooden:before { content: "\f106"; }
.flaticon-worldwide:before { content: "\f107"; }
.flaticon-map:before { content: "\f108"; }
.flaticon-computer:before { content: "\f109"; }