/* Base */
body {
  line-height: 1.7;
  color: #4d4d4d;
  font-weight: 300;
  font-size: 1rem; }

::-moz-selection {
  background: #000;
  color: #fff; }

::selection {
  background: #000;
  color: #fff; }

a {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease; }
  a:hover {
    text-decoration: none; }

h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.border-2 {
  border-width: 2px; }

.text-black {
  color: #000 !important; }

.bg-black {
  background: #000 !important; }

.color-black-opacity-5 {
  color: rgba(0, 0, 0, 0.5); }

.color-white-opacity-5 {
  color: rgba(255, 255, 255, 0.5); }

.site-wrap:before {
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  background: rgba(0, 0, 0, 0.6);
  content: "";
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden; }

.offcanvas-menu .site-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 2;
  overflow: hidden;
background-color: #8bc34a; }
  .offcanvas-menu .site-wrap:before {
    opacity: 1;
    visibility: visible; }

.btn {
  text-transform: uppercase;
  letter-spacing: .2em;
  border-radius: 0; }
  .btn:hover, .btn:active, .btn:focus {
    outline: none;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  .btn.btn-black {
    color: #fff;
    background-color: #000; }
    .btn.btn-black:hover {
      color: #000;
      background-color: #fff; }

.line-height-1 {
  line-height: 1 !important; }

.bg-black {
  background: #000; }

.form-control {
  height: 43px;
  border-radius: 0; }
  .form-control:active, .form-control:focus {
    border-color: gold; }
  .form-control:hover, .form-control:active, .form-control:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }




.site-section-card .btn{
  background-color: gold;
  border: gold;

} 
.site-section-card h2 a{
  color: gold;
}



.site-section {
  padding: 2.5em 0; }
  @media (min-width: 768px) {
    .site-section {
      padding: 5em 0; } }
  .site-section.site-section-sm {
    padding: 4em 0; }

.site-section-heading {
  position: relative;
  font-size: 2.5rem; }
  @media (min-width: 768px) {
    .site-section-heading {
      font-size: 4rem; } }
  .site-section-heading:after {
    content: "";
    left: 0%;
    bottom: 0;
    position: absolute;
    width: 100px;
    height: 1px;
    background: gold; }
  .site-section-heading.text-center:after {
    content: "";
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    left: 50%;
    bottom: 0;
    position: absolute;
    width: 100px;
    height: 1px;
    background: gold; }

.border-top {
  border-top: 1px solid #edf0f5 !important; }

.site-footer {
  padding: 4em 0;
  background: #040404; }
  @media (min-width: 768px) {
    .site-footer {
      padding: 8em 0; } }
  .site-footer .border-top {
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important; }
  .site-footer p {
    color: #737373; }
  .site-footer h2, .site-footer h3, .site-footer h4, .site-footer h5 {
    color: #fff; }
  .site-footer a {
    color: #999999; }
    .site-footer a:hover {
      color: white; }
  .site-footer ul li {
    margin-bottom: 10px; }
  .site-footer .footer-heading {
    font-size: 16px;
    color: #fff; }
    .site-footer .footer-heading p{

      background-color: gold;
    }

    .site-footer .btn{
      background-color: gold;
      border: gold;
    }

.bg-text-line {
  display: inline;
  background: #000;
  -webkit-box-shadow: 20px 0 0 #000, -20px 0 0 #000;
  box-shadow: 20px 0 0 #000, -20px 0 0 #000; }

.text-white-opacity-05 {
  color: rgba(255, 255, 255, 0.5); }

.text-black-opacity-05 {
  color: rgba(0, 0, 0, 0.5); }

.hover-bg-enlarge {
  overflow: hidden;
  position: relative; }
  @media (max-width: 991.98px) {
    .hover-bg-enlarge {
      height: auto !important; } }
  .hover-bg-enlarge > div {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .8s all ease-in-out;
    -o-transition: .8s all ease-in-out;
    transition: .8s all ease-in-out; }
  .hover-bg-enlarge:hover > div, .hover-bg-enlarge:focus > div, .hover-bg-enlarge:active > div {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }
  @media (max-width: 991.98px) {
    .hover-bg-enlarge .bg-image-md-height {
      height: 300px !important; } }

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed; }
  .bg-image.overlay {
    background-image: url('../images/imgfondodiferencial.jpg');
    position: relative; }
    .bg-image.overlay:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.7); }
  .bg-image > .container {
    position: relative;
    z-index: 1; }

@media (max-width: 991.98px) {
  .img-md-fluid {
    max-width: 100%; } }

@media (max-width: 991.98px) {
  .display-1, .display-3 {
    font-size: 3rem; } }

.play-single-big {
  width: 90px;
  height: 90px;
  display: inline-block;
  border: 2px solid #fff;
  color: #fff !important;
  border-radius: 50%;
  position: relative;
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out; }
  .play-single-big > span {
    font-size: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-40%, -50%);
    -ms-transform: translate(-40%, -50%);
    transform: translate(-40%, -50%); }
  .play-single-big:hover {
    background: rgba(255, 255, 255, 0.2);
    width: 90px;
    height: 90px; }

.overlap-to-top {
  margin-top: -150px; }

.ul-check {
  margin-bottom: 50px; }
  .ul-check li {
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    line-height: 1.5; }
    .ul-check li:before {
      left: 0;
      font-size: 20px;
      top: -.3rem;
      font-family: "icomoon";
      content: "\e5ca";
      position: absolute; }
  .ul-check.white li:before {
    color: #fff; }
  .ul-check.success li:before {
    color: #8bc34a; }
  .ul-check.primary li:before {
    color: gold; }

/* Navbar */
.logo {
  width: 60px; /* Ajusta el tamaño del contenedor según tus necesidades */
  height: 60px;
  position: relative;
  animation: girar 4s linear infinite; /* Ajusta la duración de la animación según tus necesidades */
}
@keyframes girar {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.site-navbar {
  margin-bottom: 0px;
  z-index: 1999;
  position: absolute;
  width: 100%; }
  .site-navbar.transparent {
    background: transparent; }
  .site-navbar.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
  .site-navbar .site-logo {
    position: relative;
    top: -4px; }
    .site-navbar .site-logo a {
      color: #fff; }
  .site-navbar .site-menu-toggle {
    color: #fff; }
 
    .navbar-scroll  {
    background-color: #0b0b0a;
    
    width: 100%;
    left: 0;
    height: 100px;
    justify-content: center;
    right: 0;
    top: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -webkit-box-shadow: 0 4px 15px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 15px -5px rgba(0, 0, 0, 0.1);
      }
      .navbar-scroll .site-navigation .site-menu li a {
        color: #fff; }
      .navbar-scroll .site-navigation .site-menu li a .active {
        color: gold; }

      .navbar-scroll .site-logo a {
          color: #fff !important;
        
        }
        .navbar-scroll .site-logo a img{
        width: 160px; /* Ajusta el tamaño del contenedor según tus necesidades */
  height: 80px;
  position: relative;
  animation: girar 5s linear infinite; /* Ajusta la duración de la animación según tus necesidades */
}
@keyframes girar {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}
          



/* Blocks */

.site-section .contact .btn{
  background-color: gold;
  border-color: gold;
}
.site-section .contact .btn:hover{
  opacity: 75%;
  border: 3px;
}

.site-blocks-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-position: center center; }
  .site-blocks-cover.overlay {
   /* background-image: url(../images/fondo-principal.jpg);*/
    position: relative;
    
  }

  .border-custom {
    border-bottom: 2px solid gold;
    background-clip: content-box;
  padding-bottom: 6px;
  
  }
  
  .text-custom {
    color: gold !important;
  }
   /*acordion de transportation*/
   .accordion-header button {
    background-color: gold;
    color: white;
  }
  
  .accordion-header button.collapsed {
    background-color: white;
    color: black;
  }

  .accordion-flush .accordion-button:focus{
   border-color: gold;
   background-color: rgb(250, 233, 133);
   box-shadow:  0 0 0 0.25rem rgb(253 250 13 / 25%);
   color: rgb(193, 164, 1);
  }
  .accordion-flush .accordion-button{
    color: rgb(193, 164, 1);
    background-color: #fffa98;
  }
  
  @keyframes animatedBackground {
    0% {
      background-position: 0% 0%;
      transform: translateZ(0);
    }
    100% {
      background-position: 100% 100%;
      transform: translateZ(-100px);
    }
  }
  
  .animated-background {
    animation: animatedBackground 10s linear infinite;
  }
  


    .site-blocks-cover.overlay:before {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      background: rgba(0, 0, 0, 0.4); }
      .site-blocks-cover img{
        background-image: initial;
        width: 50%;
        height: 50%;
        object-fit: cover;
        -o-object-fit: cover;
      }    
  .site-blocks-cover .player {
    position: absolute;
    bottom: -250px;
    width: 100%; }
  .site-blocks-cover, .site-blocks-cover .row {
    min-height: 600px;
    height: calc(100vh); }
  .site-blocks-cover.inner-page-cover, .site-blocks-cover.inner-page-cover .row {
    min-height: 600px;
    height: calc(30vh);
     }
  .site-blocks-cover h1 {
    font-size: 4rem;
    font-weight: 900;
    color: #fff; }
    @media (max-width: 991.98px) {
      .site-blocks-cover h1 {
        font-size: 2rem; } }
  .site-blocks-cover p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.2rem;
    line-height: 1.5; }
  .site-blocks-cover .btn {
    background: gold;
    border: 2px solid transparent; }
    .site-blocks-cover .btn:hover {
      color: #fff !important;
      background: none;
      border: 2px solid #fff; }
  .site-blocks-cover .intro-text {
    font-size: 16px;
    line-height: 1.5; }
  @media (max-width: 991.98px) {
    .site-blocks-cover .display-1 {
      font-size: 3rem; } }


      /* End block imagen principal */

.site-block-subscribe .btn {
  padding-left: 20px;
  padding-right: 20px; }

.site-block-subscribe .form-control {
  font-weight: 300;
  border-color: #fff !important;
  height: 80px;
  font-size: 22px;
  color: #fff;
  padding-left: 30px;
  padding-right: 30px; }
  .site-block-subscribe .form-control::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
    font-style: italic; }
  .site-block-subscribe .form-control::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
    font-style: italic; }
  .site-block-subscribe .form-control:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
    font-style: italic; }
  .site-block-subscribe .form-control:-moz-placeholder {
    /* Firefox 18- */
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
    font-style: italic; }

.bg-light {
  background: #ccc; }

.team-member {
  position: relative;
  float: left;
  width: 100%;
  overflow: hidden; }
  .team-member img {
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .team-member:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: gold;
    z-index: 2;
    height: 100%;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease; }
  .team-member .text {
    top: 50%;
    text-align: center;
    position: absolute;
    padding: 20px;
    -webkit-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    transform: translateY(-30%);
    -webkit-transition: .5s all ease;
    -o-transition: .5s all ease;
    transition: .5s all ease;
    opacity: 0;
    visibility: hidden;
    color: #fff; }
  .team-member:hover:before, .team-member:focus:before, .team-member:active:before {
    opacity: 1;
    visibility: visible; }
  .team-member:hover img, .team-member:focus img, .team-member:active img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); }
  .team-member:hover .text, .team-member:focus .text, .team-member:active .text {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
    opacity: 1;
    visibility: visible;
    z-index: 4; }

.podcast-entry {
  -webkit-box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 40px -10px rgba(0, 0, 0, 0.1); }
  .podcast-entry .sep {
    margin-left: 5px;
    margin-right: 5px;
    color: #ccc; }
  .podcast-entry .image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 300px; }
    @media (min-width: 768px) {
      .podcast-entry .image {
        width: 300px;
        height: auto; } }
  .podcast-entry .text {
    width: 100%;
    padding: 40px; }
    @media (min-width: 768px) {
      .podcast-entry .text {
        width: calc(100% - 300px); } }

.mejs__container {
  width: 100% !important; }

@media (max-width: 991.98px) {
  .display-4 {
    font-size: 1.8rem; } }

.feature-blocks-1 {
  position: relative;
  margin-top: -70px;
  z-index: 2;
  color: #999999; }
  .feature-blocks-1.no-margin-top {
    margin-top: 0px; }
  .feature-blocks-1 .feature-block-1 {
    position: relative;
    top: 0;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    overflow: hidden;
    z-index: 1; }
    .feature-blocks-1 .feature-block-1.bg {
      position: relative;
      background-size: cover;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
    .feature-blocks-1 .feature-block-1 .text {
      z-index: 10;
      position: relative;
      opacity: 0;
      visibility: hidden;
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
    .feature-blocks-1 .feature-block-1:before {
      content: "";
      position: absolute;
      top: 0;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0);
      -webkit-transition: .3s all ease-in-out;
      -o-transition: .3s all ease-in-out;
      transition: .3s all ease-in-out; }
    .feature-blocks-1 .feature-block-1 p {
      color: #cccccc; }
    .feature-blocks-1 .feature-block-1:hover, .feature-blocks-1 .feature-block-1:focus, .feature-blocks-1 .feature-block-1:active {
      top: -10px;
      -webkit-box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.4);
      box-shadow: 0 10px 40px -5px rgba(0, 0, 0, 0.4); }
      .feature-blocks-1 .feature-block-1:hover .text, .feature-blocks-1 .feature-block-1:focus .text, .feature-blocks-1 .feature-block-1:active .text {
        opacity: 1;
        visibility: visible; }
      .feature-blocks-1 .feature-block-1:hover.bg, .feature-blocks-1 .feature-block-1:focus.bg, .feature-blocks-1 .feature-block-1:active.bg {
        position: relative;
        background-size: cover;
        -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
        transform: scale(1.1);
        z-index: 2; }
      .feature-blocks-1 .feature-block-1:hover:before, .feature-blocks-1 .feature-block-1:focus:before, .feature-blocks-1 .feature-block-1:active:before {
        background: rgba(0, 0, 0, 0.9); }

.site-blocks-vs .bg-image {
  padding-top: 3em;
  padding-bottom: 3em; }
  @media (min-width: 992px) {
    .site-blocks-vs .bg-image {
      padding-top: 5em;
      padding-bottom: 5em; } }

.site-blocks-vs .image {
  width: 80px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80px;
  flex: 0 0 80px; }
  @media (max-width: 991.98px) {
    .site-blocks-vs .image {
      margin-left: auto !important;
      margin-right: auto !important; } }
  .site-blocks-vs .image img {
    border-radius: 50%; }
  .site-blocks-vs .image.image-small {
    width: 50px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50px;
    flex: 0 0 50px; }

.site-blocks-vs .country {
  color: rgba(255, 255, 255, 0.7); }

.match-entry {
  position: relative;
  -webkit-transition: .2s all ease-in-out;
  -o-transition: .2s all ease-in-out;
  transition: .2s all ease-in-out;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
  .match-entry .small {
    color: #ccc;
    font-weight: normal;
    letter-spacing: .15em; }
  .match-entry:hover {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 90;
    -webkit-box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 5px 40px -5px rgba(0, 0, 0, 0.1); }

.post-entry {
  position: relative;
  overflow: hidden;
  border: 1px solid #e6e6e6; }
  .post-entry, .post-entry .text {
    border-radius: 4px;
    border-top: none !important; }
  .post-entry .date {
    color: #ccc;
    font-weight: normal;
    letter-spacing: .1em; }
  .post-entry .image {
    overflow: hidden;
    position: relative; }
    .post-entry .image img {
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease;
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1); }
  .post-entry:hover .image img, .post-entry:active .image img, .post-entry:focus .image img {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }

.site-block-tab .nav-item {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin: 0 20px; }
  .site-block-tab .nav-item:first-child {
    margin-left: 0 !important; }
  .site-block-tab .nav-item > a {
    padding-left: 0 !important;
    background: none !important;
    padding-right: 0 !important;
    border-bottom: 2px solid transparent;
    border-radius: 0 !important;
    font-weight: bold;
    color: #cccccc; }
    .site-block-tab .nav-item > a:hover, .site-block-tab .nav-item > a.active {
      border-bottom: 2px solid gold;
      color: #000; }

.block-13, .slide-one-item {
  position: relative;
  z-index: 1; }
  .block-13 .owl-nav, .slide-one-item .owl-nav {
    position: relative;
    position: absolute;
    bottom: -90px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); 
    margin-bottom: 40px;}
    
    .block-13 .owl-nav .owl-prev, .block-13 .owl-nav .owl-next, .slide-one-item .owl-nav .owl-prev, .slide-one-item .owl-nav .owl-next {
      position: relative;
      display: inline-block;
      padding: 20px;
      font-size: 30px;
      color: #000; }
      .block-13 .owl-nav .owl-prev.disabled, .block-13 .owl-nav .owl-next.disabled, .slide-one-item .owl-nav .owl-prev.disabled, .slide-one-item .owl-nav .owl-next.disabled {
        opacity: .2;
        position: relative; }


.slide-one-item.home-slider .owl-nav {
  position: absolute !important;
  top: 50% !important;
  bottom: auto !important;
  width: 100%; }

.slide-one-item.home-slider .owl-prev {
  left: 10px !important; }

.slide-one-item.home-slider .owl-next {
  right: 10px !important; }

.slide-one-item.home-slider .owl-prev, .slide-one-item.home-slider .owl-next {
  color: #fff;
  position: absolute !important;
  top: 50%;
  padding: 0px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: .3s all ease-in-out;
  -o-transition: .3s all ease-in-out;
  transition: .3s all ease-in-out;
  line-height: 0;
  text-align: center;
  font-size: 25px; }
  @media (min-width: 768px) {
    .slide-one-item.home-slider .owl-prev, .slide-one-item.home-slider .owl-next {
      font-size: 25px; } }
  .slide-one-item.home-slider .owl-prev > span, .slide-one-item.home-slider .owl-next > span {
    position: absolute;
    line-height: 0;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .slide-one-item.home-slider .owl-prev:hover, .slide-one-item.home-slider .owl-prev:focus, .slide-one-item.home-slider .owl-next:hover, .slide-one-item.home-slider .owl-next:focus {
    background: black; }

.slide-one-item.home-slider:hover .owl-nav, .slide-one-item.home-slider:focus .owl-nav, .slide-one-item.home-slider:active .owl-nav {
  opacity: 10;
  visibility: visible; }

.block-12 {
  position: relative; }
  .block-12 figure {
    position: relative; }
    .block-12 figure:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
      background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .block-12 .text-overlay {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    z-index: 10; }
    .block-12 .text-overlay h2 {
      color: #fff; }
  .block-12 .text {
    position: relative;
    top: -100px; }
    .block-12 .text .meta {
      text-transform: uppercase;
      padding-left: 40px;
      color: #fff;
      display: block;
      margin-bottom: 20px; }
    .block-12 .text .text-inner {
      -webkit-box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      background: #fff;
      padding: 10px;
      margin-right: 30px;
      position: relative; }
      @media (min-width: 576px) {
        .block-12 .text .text-inner {
          padding: 20px; } }
      @media (min-width: 768px) {
        .block-12 .text .text-inner {
          padding: 30px 40px; } }
      .block-12 .text .text-inner:before {
        position: absolute;
        content: "";
        width: 80px;
        height: 4px;
        margin-top: -4px;
        background: gold;
        top: 0; }
      .block-12 .text .text-inner .heading {
        font-size: 20px;
        margin: 0;
        padding: 0; }

.block-16 figure {
  position: relative; }
  .block-16 figure .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 20px;
    width: 70px;
    height: 70px;
    background: #fff;
    display: block;
    border-radius: 50%;
    opacity: 1;
    color: gold !important; }
    .block-16 figure .play-button:hover {
      opacity: 1; }
    .block-16 figure .play-button > span {
      position: absolute;
      left: 55%;
      top: 50%;
      -webkit-transform: translate(-60%, -50%);
      -ms-transform: translate(-60%, -50%);
      transform: translate(-60%, -50%); }

.block-25 ul, .block-25 ul li {
  padding: 0;
  margin: 0; }

.block-25 ul li a .image {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 90px;
  flex: 0 0 90px; }
  .block-25 ul li a .image img {
    border-radius: 4px;
    max-width: 100%;
    opacity: 1;
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }

.block-25 ul li a .text .heading {
  font-size: 18px;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  color: #999999; }

.block-25 ul li a .meta {
  color: gold; }

.block-25 ul li a:hover img {
  opacity: .5; }

.block-25 ul li a:hover .text .heading {
  color: #fff; }

#date-countdown .countdown-block {
  color: #b3b3b3; }

#date-countdown .label {
  font-size: 40px;
  color: #000; }

.next-match .image {
  width: 50px;
  border-radius: 50%; }

.player {
  position: relative; }
  .player img {
    max-width: 100px;
    border-radius: 50%;
    margin-bottom: 20px; }
  .player .team-number {
    position: absolute;
    width: 30px;
    height: 30px;
    background: gold;
    border-radius: 50%;
    color: #fff; }
    .player .team-number > span {
      position: absolute;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%; }
  .player h2 {
    font-size: 20px;
    letter-spacing: .2em;
    text-transform: uppercase; }
  .player .position {
    font-size: 14px;
    color: #b3b3b3;
    text-transform: uppercase; }

.site-block-27 ul, .site-block-27 ul li {
  padding: 0;
  margin: 0; }

.site-block-27 ul li {
  display: inline-block;
  margin-bottom: 4px; }
  .site-block-27 ul li a, .site-block-27 ul li span {
    text-align: center;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    border: 1px solid #ccc; }
  .site-block-27 ul li.active a, .site-block-27 ul li.active span {
    background: gold;
    color: #fff;
    border: 1px solid transparent; }

.site-block-feature-7 .icon {
  -webkit-transition: .2s all ease-in-out;
  -o-transition: .2s all ease-in-out;
  transition: .2s all ease-in-out;
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.site-block-feature-7:hover .icon, .site-block-feature-7:focus .icon, .site-block-feature-7:active .icon {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2); }

.unit-1 {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block; }
  .unit-1:after {
    content: "";
    z-index: 1;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: -moz-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(18%, transparent), color-stop(99%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.8)));
    background: -o-linear-gradient(top, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, transparent 0%, transparent 18%, rgba(0, 0, 0, 0.8) 99%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 ); }
  .unit-1 img {
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1); }
  .unit-1 img, .unit-1 .unit-1-text {
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out; }
  .unit-1 .unit-1-text {
    z-index: 2;
    position: absolute;
    bottom: -90px;
    color: #fff;
    display: block;
    width: 100%; }
    .unit-1 .unit-1-text .unit-1-heading {
      font-size: 1.5rem;
      position: relative; }
  .unit-1 p {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .3s all ease;
    -o-transition: .3s all ease;
    transition: .3s all ease;
    color: rgba(255, 255, 255, 0.5); }
  .unit-1:hover p {
    opacity: 1;
    visibility: visible; }
  .unit-1:hover .unit-1-text {
    bottom: 30px; }
  .unit-1:hover img {
    -webkit-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05); }

.overlap-section {
  margin-top: -150px;
  position: relative;
  z-index: 9; }

.unit-4 .unit-4-icon span {
  line-height: 0;
  font-size: 3rem; }

.unit-4 h3 {
  font-size: 20px; }

.h-entry img {
  margin-bottom: 30px; }

.h-entry .meta {
  color: #b3b3b3;
  font-size: 14px; }

.h-entry h2 {
  font-size: 20px; }

.overlap-left {
  margin-left: -100px; }
  @media (max-width: 991.98px) {
    .overlap-left {
      margin-left: 0px; } }

.overlap-section {
  margin-top: -100px; }

.feature-1, .free-quote, .feature-3 {
  background: gold;
  padding: 30px 50px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px; }
  .feature-1 .heading, .free-quote .heading, .feature-3 .heading {
    font-size: 22px;
    text-transform: uppercase;
    color: #fff; }
  .feature-1 .icon, .free-quote .icon, .feature-3 .icon {
    border: 2px solid rgba(255, 255, 255, 0.5);
    width: 80px;
    height: 80px;
    line-height: 80px;
    position: relative;
    border-radius: 50%;
    margin: 0 auto !important; }
    .feature-1 .icon > span, .free-quote .icon > span, .feature-3 .icon > span {
      font-size: 35px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.free-quote {
  background: gold; }
  .free-quote .form-control {
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: none;
    color: #fff; }
    .free-quote .form-control:active, .free-quote .form-control:focus {
      border: 2px solid #fff; }
    .free-quote .form-control::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgba(255, 255, 255, 0.4);
      font-size: 13px;
      font-style: italic; }
    .free-quote .form-control::-moz-placeholder {
      /* Firefox 19+ */
      color: rgba(255, 255, 255, 0.4);
      font-size: 13px;
      font-style: italic; }
    .free-quote .form-control:-ms-input-placeholder {
      /* IE 10+ */
      color: rgba(255, 255, 255, 0.4);
      font-size: 13px;
      font-style: italic; }
    .free-quote .form-control:-moz-placeholder {
      /* Firefox 18- */
      color: rgba(255, 255, 255, 0.4);
      font-size: 13px;
      font-style: italic; }

.feature-3 {
  background: gold; }

.border-primary {
  position: relative; }
  .border-primary h2 {
    text-transform: uppercase;
    font-weight: 700 !important; }
  .border-primary:after {
    position: absolute;
    content: "";
    bottom: 0;
    width: 80px;
    height: 3px;
    background: gold; }
  .border-primary.text-center:after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .border-primary.text-left:after {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%); }

.testimonial {
  max-width: 800px;
  margin: 0 auto !important;
  text-align: center; }
  .testimonial blockquote {
    font-style: italic; }
    .testimonial blockquote p {
      font-size: 1.5rem; }
      .testimonial blockquote p.author {
        font-size: 1rem;
        font-style: normal; }
  .testimonial figure img {
    max-width: 100px;
    margin: 0 auto;
    border-radius: 50%; }

.breadcrumb-custom {
  font-size: 1rem !important; }

.text-muted {
  color: #ccc !important;
  font-size: 12px;
  text-transform: uppercase; }

.how-it-work-item .number {
  width: 50px;
  height: 50px;
  border: 4px solid #fff;
  background: gold;
  color: #fff;
  line-height: 44px;
  font-weight: 300;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  margin-bottom: 40px; }

.how-it-work-item h2 {
  font-size: 18px;
  margin-bottom: 30px;
  color: #fff; }

.how-it-work-item p {
  color: white; }

.person h3 {
  font-size: 18px; }

.ul-social-circle {
  list-style: none;
  padding: 0;
  margin: 0; }
  .ul-social-circle li {
    display: inline-block;
    margin-left: 5px;
    margin-bottom: 5px; }
    .ul-social-circle li:first-child {
      margin-left: 0; }
    .ul-social-circle li span {
      width: 30px;
      height: 30px;
      background: #777;
      font-size: 13px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      display: inline-block; }
    .ul-social-circle li a span {
      color: #fff;
      -webkit-transition: .3s all ease;
      -o-transition: .3s all ease;
      transition: .3s all ease; }
    .ul-social-circle li a:hover span {
      background: #2b2b2b;
      color: #fff; }

.custom-pagination a, .custom-pagination span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  background: gold;
  color: #fff; }

.custom-pagination span {
  background: #e6e6e6;
  color: #000; }

.sidebar-box {
  margin-bottom: 30px;
  padding: 25px;
  font-size: 15px;
  width: 100%;
  float: left;
  background: #fff; }
  .sidebar-box *:last-child {
    margin-bottom: 0; }
  .sidebar-box h3 {
    font-size: 18px;
    margin-bottom: 15px; }

.categories li,
.sidelink li {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #dee2e6;
  list-style: none; }
  .categories li:last-child,
  .sidelink li:last-child {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0; }
  .categories li a,
  .sidelink li a {
    display: block; }
    .categories li a span,
    .sidelink li a span {
      position: absolute;
      right: 0;
      top: 0;
      color: #ccc; }
  .categories li.active a,
  .sidelink li.active a {
    color: #000;
    font-style: italic; }

.comment-form-wrap {
  clear: both; }

.comment-list {
  padding: 0;
  margin: 0; }
  .comment-list .children {
    padding: 50px 0 0 40px;
    margin: 0;
    float: left;
    width: 100%; }
  .comment-list li {
    padding: 0;
    margin: 0 0 30px 0;
    float: left;
    width: 100%;
    clear: both;
    list-style: none; }
    .comment-list li .vcard {
      width: 80px;
      float: left; }
      .comment-list li .vcard img {
        width: 50px;
        border-radius: 50%; }
    .comment-list li .comment-body {
      float: right;
      width: calc(100% - 80px); }
      .comment-list li .comment-body h3 {
        font-size: 20px;
        color: #000; }
      .comment-list li .comment-body .meta {
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: .1em;
        color: #ccc; }
      .comment-list li .comment-body .reply {
        padding: 5px 10px;
        background: #e6e6e6;
        color: #000;
        text-transform: uppercase;
        font-size: 14px; }
        .comment-list li .comment-body .reply:hover {
          color: #000;
          background: #e3e3e3; }

.search-form {
  background: #f7f7f7;
  padding: 10px; }
  .search-form .form-group {
    position: relative; }
    .search-form .form-group input {
      padding-right: 50px; }
  .search-form .icon {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); }

.post-meta {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: .2em; }
  .post-meta a {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5); }

